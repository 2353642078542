.ant-breadcrumb{
    display: flex;
    align-items: center;
    span{
        .breadcrumb-item{
            display:flex;
            align-items: center;
            justify-content: center;
            height:36px;
            padding:0 20px;
            color:#fff;
            font-size:16px;
            background:#d9d9d9;
            a {
                color:#fff;
            }
    
            &:last-child {
                a {
                    color:#fff;
                }
            }
    
            &.active{
                background: #4eaaeb;
                a {
                    color:#fff;
                }
            }
    
            &:hover{
                background: #4eaaeb;
                a {
                    color:#fff;
                    &.hover{
                        color:#fff;
                    }
                }
            }
        }
        &:last-child {
            .breadcrumb-item{
                background:#4eaaeb;
                a {
                    color:#fff;
                }
            }
        }
    }
    
}


@primary-color: #4EAAEB;