.layout-header{
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background:#fff;
    
    .header-left{
        width: 363px;
        height: 71px;
        background:url(../../assets/images/logo_02.jpg) no-repeat left center;
        background-size:363px 71px;
    }

    .header-right{
        display:flex;
        
        .info-item{
            font-size: 14px;
            color: #777;
            cursor: pointer;
            &:hover{
                color:#4EAAEB;
            }
            &.user_name{
                cursor:default;
                &:hover{
                    color:#777;
                }
            }
            &:after{
                content: '';
                width: 1px;
                height: 12px;
                background: #ddd;
                display: inline-block;
                margin-left: 10px;
                margin-right: 10px;
            }
            &:last-child{
                &:after{
                    display:none;
                }
            }
        }
    }
}

@primary-color: #4EAAEB;