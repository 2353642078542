#loginContent{
    width:100%;
    height:100%;
    position: relative;
    background:dodgerblue;
    background:#f7f7f7;

    .login-logo{
        width:1200px;
        height:110px;
        background:url(../../assets/images/logo_01.png) left center no-repeat;
        margin:0 auto;
    }

    .login-card-box{
        display:flex;
        justify-content: flex-end;
        align-items: center;
        height:580px;
        background:url(../../assets/images/bg.jpg) center no-repeat;
        .login-card{
            width: 380px;
            height:403px;
            margin-right:200px;
            background:#fff;
            border-radius:20px;
            display:flex;
            flex-direction: column;
            align-items: center;
            padding-top:42px;
        
            .login-card-title{
                font-size: 18px;
                color:#333;
                margin-bottom:50px;
            }

            .login-form{
                display:flex;
                flex-direction: column;
                align-items: center;

                .ant-form-item-control{
                    margin-bottom:20px;
                }

                .input-item{
                    width:290px;
                    height:44px;
                    background:#fff;
                    border:1px solid #e9e9e9;
                    border-radius:22px;
                    // margin-bottom:30px;
                    
                    .ant-input{
                        color:#333;
                        font-size:16px;
                        background:#fff;
                    }
                }

                .site-form-item-icon{
                    width:12px;
                    height:17px;
                    color:#e1e1e1;
                }

                .login-form-button{
                    width:268px;
                    height:48px;
                    color:#fff;
                    font-size: 16px;
                    background:none;
                    background:linear-gradient(90deg,#01A4FF,#1259EE);
                    box-shadow: 0px 7px 10px 0px rgba(42,161,246,0.18);
                    border:none;
                    border-radius:24px;
                    margin-top:10px;
                }
            }
        }
    }

    .login-icp{
        height:60px;
        line-height: 60px;
        color:#777;
        text-align: center;
    }
}
@primary-color: #4EAAEB;