
html, body, div,
span,h1, h2, h3, h4, h5, h6, p,
a,
img,
i,
ol, ul, li,
form, label,
table, tbody, tfoot, thead, tr, th, td, input {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing:border-box;
    font-family: inherit;
}

body{font-family: "Microsoft YaHei", "微软雅黑", "Heiti SC", "DroidSans", "DroidSansFallback", "Helvetica Neue", Helvetica, "STHeiTi", Arial, sans-serif;background:#f8f8f8;}

h1, h2, h3, h4, h5, h6, p{
    font-weight: normal;
}

i,em{
    font-style: normal;
}

a:hover{
    text-decoration: none;
}

ol, ul {
    list-style: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

// 多行省略
.ellipsis{
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}

// 表格Card公共样式
.card-table-box{
    margin-top:30px;
    border-radius:10px;
}

.page-loading{
    height:100%;
    .ant-spin-container{
        height:100%;
    }
}
@primary-color: #4EAAEB;