.edit-password-form{
    .tip{
        color:#ff0000;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .text{
            margin-bottom:5px;
        }
    }
    .input-password{
        width:200px;
    }
}
@primary-color: #4EAAEB;